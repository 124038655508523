* {
    box-sizing: border-box;
    font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

:root {
    /* --blue: #247fceee ; */
    --blue: #ce2424ee ;

}

 body {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    
}
/* outside border */
.card {
    color: rgb(255, 255, 255);
    background: var(--blue);
    border-radius: 20px;
    padding: 20px;
    max-width: 400px;
}

#bg {
    height: 100%;
    background-size: cover;
}

.card h1 {
    font-size: 20px;
}

.card p {
    font-size: 20px;
}

.mt-16 {
    margin-top: 12px;
}

.text-center{
    text-align: center;
}
  

.card .btn_action {
    padding: 8px 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    width: 100%;
    display: block;
    border-radius: 10px;
    border: 1px solid var(--blue);
    font-size: 16px;
    transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card .btn_action span{
    margin-left: 8px;
}

.card .btn_action:hover {
    background: var(--blue);
    border: 1px solid rgba(255, 255, 255, 0.187);
}

/* inside border */
.bg_content{
    background: #2927279a;
    font-size: larger;
}

.rd_12{
    border-radius: 12px;
}

.p_8{
    padding: 8px;
}

.higthlight{
    color: #247fce;
    font-weight: 700;
} 

.ideas{
    color: #247fce;
    font-weight: 700;
    font-size: 35px;
   
}



